import React, { useState } from 'react';
import './css/productsCardMob.css'
import StandardContainer from './StandardContainer';
import SmartContainer from './SmartContainer';
import VipContainer from './VipContainer';
import SpecialContainer from './SpecialContainer';
import StandardBoxExp from './ProductsBoxExplanation/StandardBoxExp';
import SmartBoxExp from './ProductsBoxExplanation/SmartBoxExp';
import VipBoxExp from './ProductsBoxExplanation/VipBoxExp';
import SpecialBoxExp from './ProductsBoxExplanation/SpecialBoxExp';

export default function ProductsCard({ productsInfo }) {
  // Estado para controlar quais containers estão expandidos
  const [expandedCategory, setExpandedCategory] = useState(null);

  // Função para alternar o container expandido
  const toggleExpand = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  // Separar os valores por categorias
  const standard = {
    standard1: productsInfo.standard1,
    standard3: productsInfo.standard3,
    standard6: productsInfo.standard6,
    standard12: productsInfo.standard12,
  };

  const vip = {
    vip1: productsInfo.vip1,
    vip3: productsInfo.vip3,
    vip6: productsInfo.vip6,
    vip12: productsInfo.vip12,
  };

  const smart = {
    smart1: productsInfo.smart1,
    smart3: productsInfo.smart3,
    smart6: productsInfo.smart6,
    smart12: productsInfo.smart12,
  };

  const special = {
    special1: productsInfo.special1,
    special3: productsInfo.special3,
    special6: productsInfo.special6,
    special12: productsInfo.special12,
  };

  // Função para renderizar os detalhes de uma categoria
  const renderCategoryDetails = (category) => {
    switch (category) {
      case 'standard':
        return <StandardContainer standard={standard} />;
      case 'smart':
        return <SmartContainer smart={smart} />;
      case 'vip':
        return <VipContainer vip={vip} />;
      case 'special':
        return <SpecialContainer special={special} />;
      default:
        return null;
    }
  };
  return (
  <div>
    <div className='products-card-main-container'>
      <div className='products-card-container'>
        <div className='standard-title-box'>
        <span className='box-title-font'>Standard</span>
        </div>
        <StandardBoxExp toggleExpand={() => toggleExpand('standard')} />
        {expandedCategory === 'standard' && renderCategoryDetails('standard')}
      </div>

      <div className='products-card-container'>
        <div className='smart-title-box'>
        <span className='box-title-font'>Smart</span>
        </div>
        <SmartBoxExp toggleExpand={() => toggleExpand('smart')} />
        {expandedCategory === 'smart' && renderCategoryDetails('smart')}
      </div>

      <div className='products-card-container'>
        <div className='vip-title-box'>
        <span className='box-title-font'>VIP</span>
        </div>
        <VipBoxExp toggleExpand={() => toggleExpand('vip')} />
        {expandedCategory === 'vip' && renderCategoryDetails('vip')}
      </div>

      <div className='products-card-container'>
        <div className='special-title-box'>
        <span className='box-title-font'>Special</span>
        </div>
        <SpecialBoxExp toggleExpand={() => toggleExpand('special')} />
        {expandedCategory === 'special' && renderCategoryDetails('special')}
      </div>
    </div>
  </div>
  );
}


