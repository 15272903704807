import React from 'react';

export default function SmartInfoValues({ productName }) {
  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Smart One' && (
        <ul>
        <li>1 crédito para manutenção de aparelho convencional.</li>
        <p>Benefícios desbloqueados:</p>
        <li>1 crédito para limpeza dentária(profilaxia).</li>
      </ul>
      )}
      {productName === 'Smart Tri' && (
        <ul>
        <li>3 créditos para manutenção de aparelho convencional.</li>
        <p>Benefícios desbloqueados:</p>
        <li>3 créditos para limpeza dentária(profilaxia).</li>
      </ul>
      )}
      {productName === 'Smart Six' && (
        <ul>
        <li>6 créditos para manutenção de aparelho convencional.</li>
        <p>Benefícios desbloqueados:</p>
        <li>6 créditos para limpeza dentária(profilaxia).</li>
        <li>1 créditos para restauração dentária(obturação).</li>
      </ul>
      )}
      {productName === 'Smart Dozen' && (
        <ul>
        <li>12 créditos para manutenção de aparelho convencional.</li>
        <p>Benefícios desbloqueados:</p>
        <li>12 créditos para limpeza dentária(profilaxia).</li>
        <li>2 créditos para restauração dentária(obturação).</li>
      </ul>
      )}
    </div>
  );
}
