import React, { useState } from 'react';
import './css/productsCardMob.css';
import VipInfoValues from './prodctsInfosValues/VipInfoValues';

export default function VipContainer({ vip }) {
  // Estado que controla quais produtos estão expandidos
  const [expandedItems, setExpandedItems] = useState({});

  // Função para alternar o estado de um produto específico
  const toggleExpand = (item) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item] // Alterna o estado de exibição
    }));
  };

  return (
    <div className='products-card-sub-container'>
      <div className='product-infos-container-vip' onClick={() => toggleExpand('vipOne')}>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>VIP ONE</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {vip.vip1},00 no Pix</span>
        </div>
        <span style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.vipOne && <VipInfoValues productName="Vip One" />}
      </div>

      <div className='product-infos-container-vip' onClick={() => toggleExpand('vipTri')}>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>VIP TRI</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {vip.vip3},00 no Pix</span>
        </div>
        <span style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.vipTri && <VipInfoValues productName="Vip Tri" />}
      </div>

      <div className='product-infos-container-vip' onClick={() => toggleExpand('vipSix')}>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>VIP SIX</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {vip.vip6},00 no Pix</span>
        </div>
        <span style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.vipSix && <VipInfoValues productName="Vip Six" />}
      </div>

      <div className='product-infos-container-vip' onClick={() => toggleExpand('vipDozen')}>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span style={{width:'50vw',textAlign:'left'}}>VIP DOZEN</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {vip.vip12},00 no Pix</span>
        </div>
        <span style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.vipDozen && <VipInfoValues productName="Vip Dozen" />}
      </div>
    </div>
  );
}
