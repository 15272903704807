import React from 'react';
import './css/info-values.css'

export default function StandardInfoValues({ productName }) {
  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Standard One' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para manutenção de aparelho convencional.</li>
        </ul>
      )}
      {productName === 'Standard Tri' && (
        <ul>
          <li>3 créditos para manutenção de aparelho convencional.</li>
        </ul>
      )}
      {productName === 'Standard Six' && (
        <ul>
          <li>6 créditos para manutenção de aparelho convencional.</li>
          <p>Benefícios desbloqueados:</p>
          <li>1 crédito para limpeza dentária(profilaxia).</li>
        </ul>
      )}
      {productName === 'Standard Dozen' && (
        <ul>
          <li>12 créditos para manutenção de aparelho convencional.</li>
          <p>Benefícios desbloqueados:</p>
          <li>2 créditos para limpeza dentária(profilaxia).</li>
          <li>1 crédito para restauração dentária(obturação).</li>
        </ul>
      )}
      <button className='button-info-values'>ADQUIRIR!</button>
    </div>
  );
}
