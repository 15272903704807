import React from 'react';

export default function SpecialInfoValues({ productName }) {
  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Special One' && (
         <ul>
         <li>1 crédito para manutenção de aparelho estético ou autoligado.</li>
         <p>Benefícios desbloqueados:</p>
         <li>Prioridade na fila de espera(fila VIP)</li>
         <li>1 crédito para limpeza dentária(profilaxia).</li>
       </ul>
      )}
      {productName === 'Special Tri' && (
        <ul>
        <li>3 créditos para manutenção de aparelho estético ou autoligado.</li>
        <p>Benefícios desbloqueados:</p>
        <li>Prioridade na fila de espera(fila VIP)</li>
        <li>3 créditos para limpeza dentária(profilaxia).</li>
        <li>1 crédito para restauração dentária(obturação).</li>
      </ul>
      )}
      {productName === 'Special Six' && (
        <ul>
        <li>6 créditos para manutenção de aparelho estético ou autoligado. </li>
        <p>Benefícios desbloqueados:</p>
        <li>Prioridade na fila de espera(fila VIP)</li>
        <li>6 créditos para limpeza dentária(profilaxia).</li>
        <li>2 créditos para restauração dentária(obturação).</li>
      </ul>
      )}
      {productName === 'Special Dozen' && (
        <ul>
        <li>12 créditos para manutenção de aparelho estético ou autoligado.</li>
        <p>Benefícios desbloqueados:</p>
        <li>Prioridade na fila de espera(fila VIP)</li>
        <li>12 créditos para limpeza dentária(profilaxia).</li>
        <li>3 créditos para restauração dentária(obturação).</li>
        <li>1 crédito para tratamento de canal</li>
        <li>1 kit de clareamento caseiro(plaquinha + gel)</li>
        <li>1 kit de higiene bucal para aparelhos.</li>
      </ul>
      )}
    </div>
  );
}
