import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderOrtho from '../../components/header/HeaderOrtho';
import ProductsCard from '../../components/productsCard/ProductsCard';
import axios from 'axios'; // Biblioteca para fazer chamadas HTTP
import ProductsCardCover from '../../components/productsCard/ProductsCardCover';
import './css/patient-profile.css'

const PatientProfile = () => {
  const { id } = useParams(); // Obtém o 'id' dos parâmetros da URL

  // States para armazenar os dados
  const [productsInfo, setProductsInfo] = useState(null); // Armazena o objeto de produtos
  const [patientInfos, setPatientInfos] = useState(null); // Armazena o objeto do paciente, sem o campo password

  // Função para buscar os produtos
  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://localhost:3001/credits/products');
      if (response.data.length > 0) {
        setProductsInfo(response.data[0]); // Salva apenas o primeiro objeto do array
      }
    } catch (error) {
      console.error('Erro ao buscar os produtos:', error);
    }
  };

  // Função para buscar os dados do paciente
  const fetchPatient = async () => {
    try {
      const response = await axios.get(`http://localhost:3001/patients/${id}`);
      const patientData = response.data;
      // Remove o campo password do objeto de paciente
      const { password, ...patientWithoutPassword } = patientData;
      setPatientInfos(patientWithoutPassword); // Salva o objeto sem o campo password
    } catch (error) {
      console.error('Erro ao buscar os dados do paciente:', error);
    }
  };

  // useEffect para fazer o fetch quando o componente for montado
  useEffect(() => {
    fetchProducts();
    fetchPatient();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); // Reexecuta o efeito sempre que o 'id' mudar

  return (
    <div>
      <HeaderOrtho />
      <ProductsCardCover/>
      <div>
        <h2>Bem vindo {patientInfos && patientInfos.name} </h2>
      </div>
      {/* Passa o productsInfo como props para o ProductsCard */}
      {productsInfo && <ProductsCard productsInfo={productsInfo} />}
    </div>
  );
};

export default PatientProfile;
