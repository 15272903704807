import React from 'react';

export default function VipInfoValues({ productName }) {
  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Vip One' && (
         <ul>
         <li>1 crédito para manutenção de aparelho convencional.</li>
         <p>Benefícios desbloqueados:</p>
         <li>Prioridade na fila de espera(fila VIP)</li>
         <li>1 crédito para limpeza dentária(profilaxia).</li>
         <li>Reparo vip, reparos no aparelho durante 30 dias após credito utilizado.</li>
       </ul>
      )}
      {productName === 'Vip Tri' && (
        <ul>
        <li>3 créditos para manutenção de aparelho convencional.</li>
        <p>Benefícios desbloqueados:</p>
        <li>Prioridade na fila de espera(fila VIP)</li>
        <li>3 créditos para limpeza dentária(profilaxia).</li>
        <li>1 crédito para restauração dentária(obturação).</li>
        <li>Reparo vip, reparos no aparelho durante 30 dias após credito utilizado.</li>
      </ul>
      )}
      {productName === 'Vip Six' && (
        <ul>
        <li>6 créditos para manutenção de aparelho convencional.</li>
        <p>Benefícios desbloqueados:</p>
        <li>Prioridade na fila de espera(fila VIP)</li>
        <li>6 créditos para limpeza dentária(profilaxia).</li>
        <li>2 créditos para restauração dentária(obturação).</li>
        <li>Reparo vip, reparos no aparelho durante 30 dias após credito utilizado.</li>
      </ul>
      )}
      {productName === 'Vip Dozen' && (
        <ul>
          <li>12 créditos para manutenção de aparelho convencional.</li>
          <p>Benefícios desbloqueados:</p>
          <li>Prioridade na fila de espera(fila VIP)</li>
          <li>12 créditos para limpeza dentária(profilaxia).</li>
          <li>3 créditos para restauração dentária(obturação).</li>
          <li>1 crédito para tratamento de canal</li>
          <li>Reparo vip, reparos no aparelho durante 30 dias após credito utilizado.</li>
          <li>1 kit de clareamento caseiro(plaquinha + gel)</li>
          <li>1 kit de higiene bucal para aparelhos.</li>
        </ul>
      )}
    </div>
  );
}
